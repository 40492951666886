import { useCallback, useEffect, useRef, useState } from "react";

export default function useDragAndDrop() {
  const reader = new window.FileReader();

  const drop = useRef(null);

  const [onUpload, setOnUpload] = useState([]);
  const [file, setFile] = useState();
  const [percentage, setPercentage] = useState(0);

  // HELPER FUNCTION:
  const fileUpload = useCallback((file) => {
    reader.onload = (e) => {
      setFile(reader.result);
    };
    reader.readAsDataURL(file[0]);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dropEl = drop.current;
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const files = [...e.dataTransfer.files];

      // if (files[0].type.substr(0, 5) === "image") {
      setOnUpload(files);
      fileUpload(files);
      // }
    };

    const handleProgress = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let percent = Math.round((e.loaded / e.total) * 100);
      setPercentage(percent);
    };
    dropEl.addEventListener("dragover", handleDragOver);
    dropEl.addEventListener("drop", handleDrop);
    reader.addEventListener("progress", handleProgress);

    return () => {
      dropEl.removeEventListener("dragover", handleDragOver);
      dropEl.removeEventListener("drop", handleDrop);
      reader.removeEventListener("progress", handleProgress);
    };
    //eslint-disable-next-line
  }, [fileUpload]);

  const handleFile = (e) => {
    setOnUpload([e.target.files[0]]);
    fileUpload([e.target.files[0]]);
  };

  return { drop, onUpload, handleFile, file, percentage };
}
