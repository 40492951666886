import React, { useState } from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";
import JobApplication from "../components/JobApplication/JobApplication";

// markup
const IndexPage = () => {
  const [hiddenNav, setHiddenNav] = useState(false);

  return (
    <Layout hiddenNav={hiddenNav}>
      <Seo title="Jobba hos oss - Putso" />
      <JobApplication
        emailCitySufix=""
        setHiddenNav={setHiddenNav}
        hiddenNav={hiddenNav}
      />
    </Layout>
  );
};

export default IndexPage;
