import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

const initialValues = {
  "Hur länge har du tidigare arbetat med städning?": "",
  "Hur många timmar per vecka skulle du vilja arbeta på Putso?": "",
  "När kan du arbeta?": "",
  "Har du tidigare blivit dömd eller håller på att dömas för brott?": "",
  "Har du körkort?": "",
  "Har du tillgång till egen bil?": "",
  "Är du rädd för hundar / katter?": "",
  "Har du telefon med internet?": "",
};

const registrationSchema = Yup.object().shape({
  "Hur länge har du tidigare arbetat med städning?": Yup.string().required(
    "Fältet är obligatoriskt"
  ),
  "Hur många timmar per vecka skulle du vilja arbeta på Putso?":
    Yup.string().required("Fältet är obligatoriskt"),
  "När kan du arbeta?": Yup.string().required("Fältet är obligatoriskt"),
  "Har du tidigare blivit dömd eller håller på att dömas för brott?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Har du körkort?": Yup.string().required("Fältet är obligatoriskt"),
  "Har du tillgång till egen bil?": Yup.string().required(
    "Fältet är obligatoriskt"
  ),
  "Är du rädd för hundar / katter?": Yup.string().required(
    "Fältet är obligatoriskt"
  ),
  "Har du telefon med internet?": Yup.string().required(
    "Fältet är obligatoriskt"
  ),
});

export default function Step1({ onSubmit, active }) {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      // const data = {
      //   email: values.companyEmail,
      //   password: values.password,
      //   websiteURL: values.websiteURL,
      //   companyName: values.companyName,
      //   slug: values.slug,
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   phone: values.phoneNumber,
      // };
      // dispatch(register(data, () => setRegistered(true)));
    },
  });
  return (
    <form
      className={"space-y-6" + (!active ? " hidden" : "")}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="question-holder">
        <div className="question">
          1. Hur länge har du tidigare arbetat med städning?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur länge har du tidigare arbetat med städning?"
              )}
              value="Jag har aldrig arbetat med städning"
            />
            Jag har aldrig arbetat med städning
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur länge har du tidigare arbetat med städning?"
              )}
              value="1-6 månader"
            />
            1-6 månader
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur länge har du tidigare arbetat med städning?"
              )}
              value="6-12 månader"
            />
            6-12 månader
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur länge har du tidigare arbetat med städning?"
              )}
              value="mer än 13 månader"
            />
            mer än 13 månader
          </label>
        </div>
        {formik.touched["Hur länge har du tidigare arbetat med städning?"] &&
          formik.errors["Hur länge har du tidigare arbetat med städning?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["Hur länge har du tidigare arbetat med städning?"]}
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          2. Hur många timmar per vecka skulle du vilja arbeta på Putso?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
              )}
              value="1-10"
            />
            1-10
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
              )}
              value="10-20"
            />
            10-20
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
              )}
              value="20-30"
            />
            20-30
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
              )}
              value="mer än 30"
            />
            mer än 30
          </label>
        </div>
        {formik.touched[
          "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
        ] &&
          formik.errors[
            "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Hur många timmar per vecka skulle du vilja arbeta på Putso?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">3. När kan du arbeta?</div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("När kan du arbeta?")}
              value="Hela dagen"
            />
            Hela dagen
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("När kan du arbeta?")}
              value="Bara på förmiddagen"
            />
            Bara på förmiddagen
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("När kan du arbeta?")}
              value="Bara på eftermiddagen"
            />
            Bara på eftermiddagen
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("När kan du arbeta?")}
              value="Bara helger"
            />
            Bara helger
          </label>
        </div>
        {formik.touched["När kan du arbeta?"] &&
          formik.errors["När kan du arbeta?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["När kan du arbeta?"]}
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          4. Har du tidigare blivit dömd eller håller på att dömas för brott?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Har du tidigare blivit dömd eller håller på att dömas för brott?"
              )}
              value="Nej"
            />
            Nej
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Har du tidigare blivit dömd eller håller på att dömas för brott?"
              )}
              value="Ja"
            />
            Ja
          </label>
        </div>
        {formik.touched[
          "Har du tidigare blivit dömd eller håller på att dömas för brott?"
        ] &&
          formik.errors[
            "Har du tidigare blivit dömd eller håller på att dömas för brott?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Har du tidigare blivit dömd eller håller på att dömas för brott?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">5. Har du körkort?</div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du körkort?")}
              value="Ja"
            />
            Ja
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du körkort?")}
              value="Nej"
            />
            Nej
          </label>
        </div>
        {formik.touched["Har du körkort?"] &&
          formik.errors["Har du körkort?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["Har du körkort?"]}
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">6. Har du tillgång till egen bil?</div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du tillgång till egen bil?")}
              value="Ja"
            />
            Ja
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du tillgång till egen bil?")}
              value="Nej"
            />
            Nej
          </label>
        </div>
        {formik.touched["Har du tillgång till egen bil?"] &&
          formik.errors["Har du tillgång till egen bil?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["Har du tillgång till egen bil?"]}
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">7. Är du rädd för hundar / katter?</div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Är du rädd för hundar / katter?")}
              value="Ja"
            />
            Ja
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Är du rädd för hundar / katter?")}
              value="Nej"
            />
            Nej
          </label>
        </div>
        {formik.touched["Är du rädd för hundar / katter?"] &&
          formik.errors["Är du rädd för hundar / katter?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["Är du rädd för hundar / katter?"]}
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">8. Har du telefon med internet?</div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du telefon med internet?")}
              value="Ja"
            />
            Ja
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps("Har du telefon med internet?")}
              value="Nej"
            />
            Nej
          </label>
        </div>
        {formik.touched["Har du telefon med internet?"] &&
          formik.errors["Har du telefon med internet?"] && (
            <span className="text-red-600 text-xs">
              {formik.errors["Har du telefon med internet?"]}
            </span>
          )}
      </div>

      <div className="flex justify-end md:px-0 px-5 w-full mt-11">
        <button type="submit" disabled={!formik.isValid}>
          Fortsätt
        </button>
      </div>
    </form>
  );
}
