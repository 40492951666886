import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import BackButton from "../Booking/BackButton/BackButton";

const initialValues = {
  "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?": "",
  "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?":
    "",
  "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?":
    "",
  "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?": "",
  "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?":
    "",
  "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?":
    "",
};

const registrationSchema = Yup.object().shape({
  "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?":
    Yup.string().required("Fältet är obligatoriskt"),
  "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?":
    Yup.string().required("Fältet är obligatoriskt"),
});

export default function Step2({ onBack, active, onSubmit }) {
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      // const data = {
      //   email: values.companyEmail,
      //   password: values.password,
      //   websiteURL: values.websiteURL,
      //   companyName: values.companyName,
      //   slug: values.slug,
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   phone: values.phoneNumber,
      // };
      // dispatch(register(data, () => setRegistered(true)));
    },
  });

  console.log("values", formik.values);

  return (
    <form
      className={"space-y-6" + (!active ? " hidden" : "")}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="question-holder">
        <div className="question">
          Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
              )}
              value="Direkt i början av städningen"
            />
            Direkt i början av städningen
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
              )}
              value="Efter avdamning och rengöring av möbler"
            />
            Efter avdamning och rengöring av möbler
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
              )}
              value="Halvvägs in i städningen"
            />
            Halvvägs in i städningen
          </label>
        </div>
        {formik.touched[
          "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
        ] &&
          formik.errors[
            "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 1: Du städar ett rum, När dammsuger du och moppar golvet?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något
          speciellt medel?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
              )}
              value="Med en mopp och mycket vatten"
            />
            Med en mopp och mycket vatten
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
              )}
              value="Med en mopp och väldigt lite vatten"
            />
            Med en mopp och väldigt lite vatten
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
              )}
              value="Bara dammsugning och ingen moppning"
            />
            Bara dammsugning och ingen moppning
          </label>
        </div>
        {formik.touched[
          "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
        ] &&
          formik.errors[
            "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 2: Hur rengör du parkett (trä) golv om det inte finns något speciellt medel?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin.
          Vad gör du?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
              )}
              value="Jag använder klorinet med mycket vatten"
            />
            Jag använder klorinet med mycket vatten
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
              )}
              value="Jag använder inte klorinet, jag ber om speciellt medel för trä"
            />
            Jag använder inte klorinet, jag ber om speciellt medel för trä
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
              )}
              value="Jag använder klorinet direkt på trä"
            />
            Jag använder klorinet direkt på trä
          </label>
        </div>
        {formik.touched[
          "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
        ] &&
          formik.errors[
            "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 3: Kunden ber dig rengöra ett bord av trä och ger dig klorin, Vad gör du?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          Fråga 4: Du är 15 minuter sen till din kund. Vad gör du?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
              )}
              value="Det är bara 15 minuter, så jag behöver inte säga till någon"
            />
            Det är bara 15 minuter, så jag behöver inte säga till någon
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
              )}
              value="Avboka städningen. Om jag redan är sen så behöver jag inte gå."
            />
            Avboka städningen. Om jag redan är sen så behöver jag inte gå.
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
              )}
              value="Kontaktar min arbetsledare direkt och talar om det för honom och stannar hos kunden och städar 15 minuter extra. "
            />
            Kontaktar min arbetsledare direkt och talar om det för honom och
            stannar hos kunden och städar 15 minuter extra.
          </label>
        </div>
        {formik.touched[
          "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
        ] &&
          formik.errors[
            "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 4: Du är 15 minuter sen till din kund, Vad gör du?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          Fråga 5: Om din kund ber dig att städa något extra (som inte finns i
          städbeskrivningen) som tar längre tid. Vad gör du?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
              )}
              value="Rengör det som kunden ber mig om"
            />
            Rengör det som kunden ber mig om
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
              )}
              value="Ber kunden ringa min arbetsledare och fråga om det går att lägga till"
            />
            Ber kunden ringa min arbetsledare och fråga om det går att lägga
            till
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
              )}
              value="Struntar i att göra det"
            />
            Struntar i att göra det
          </label>
        </div>
        {formik.touched[
          "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
        ] &&
          formik.errors[
            "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 5: Om din kund ber dig att städa något extra (som inte finns i städbeskrivningen) som tar längre tid, Vad gör du?"
                ]
              }
            </span>
          )}
      </div>

      <div className="question-holder">
        <div className="question">
          Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare.
          Nästa kund börjar klockan 13-17. Vad gör du?
        </div>
        <div
          role="group"
          className="radios-holder"
          aria-labelledby="my-radio-group"
        >
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
              )}
              value="Går därifrån och tar tidig lunch så att jag kan börja 15 minuter tidigare hos nästa kund"
            />
            Går därifrån och tar tidig lunch så att jag kan börja 15 minuter
            tidigare hos nästa kund
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
              )}
              value="Städar något extra i 15 minuter"
            />
            Städar något extra i 15 minuter
          </label>
          <label>
            <input
              type="radio"
              {...formik.getFieldProps(
                "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
              )}
              value="Stannar kvar och väntar på att min tid ska ta slut"
            />
            Stannar kvar och väntar på att min tid ska ta slut
          </label>
        </div>
        {formik.touched[
          "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
        ] &&
          formik.errors[
            "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
          ] && (
            <span className="text-red-600 text-xs">
              {
                formik.errors[
                  "Fråga 6: Du har en kund från 8-12 och blir färdig 15 minuter tidigare, Nästa kund börjar klockan 13-17, Vad gör du?"
                ]
              }
            </span>
          )}
      </div>

      <div className="flex md:justify-between justify-end md:px-0 px-5 w-full mt-11">
        <BackButton text="Tillbaka" onClick={onBack} />
        <button type="submit" disabled={!formik.isValid}>
          Fortsätt
        </button>
      </div>
    </form>
  );
}
