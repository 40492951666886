import React from "react";
import useDragAndDrop from "../../helpers/useDragAndDrop";
import BackButton from "../Booking/BackButton/BackButton";
import FormInput from "../FormInput/FormInput";

export default function Step3({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  personNumber,
  setPersonNumber,
  notes,
  setNotes,
  emailCitySufix,
  onBack,
  handleSubmit,
}) {
  const { drop, onUpload, handleFile, file, percentage } = useDragAndDrop();

  console.log("file", file);
  console.log("onUpload", onUpload);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(
          file && onUpload.length
            ? {
                base64: file,
                name: onUpload[0].name,
                type: onUpload[0].type,
              }
            : {}
        );
      }}
    >
      <div className="job-step-3">
        <h1 className="mb-2">Snart klart...</h1>
        <p className="mb-6">
          Nu behöver vi bara några sista uppgifter att kunna komma i kontakt med
          dig.
        </p>
        <div className="md:flex md:space-x-6 md:space-y-0 space-y-6 mb-5">
          <FormInput
            placeholder="Förnamn"
            value={firstName}
            onChange={(val) => setFirstName(val)}
          />
          <FormInput
            placeholder="Efternamn"
            value={lastName}
            onChange={(val) => setLastName(val)}
          />
        </div>
        <div className="md:flex md:space-x-6 md:space-y-0 space-y-6 mb-5">
          <FormInput
            placeholder="Mailadress"
            type="email"
            value={email}
            onChange={(val) => setEmail(val)}
          />
          <FormInput
            placeholder="Mobilnummer"
            type="tel"
            value={phone}
            onChange={(val) => setPhone(val)}
          />
        </div>
        <div className="mb-5">
          <FormInput
            placeholder="Personnummer"
            type="text"
            value={personNumber}
            onChange={(val) => setPersonNumber(val)}
          />
        </div>
        <FormInput
          textarea
          placeholder="Berätta lite kort om varför du skulle vilja arbeta på Putso (frivilligt)"
          value={notes}
          onChange={(val) => setNotes(val)}
        />
      </div>
      <div className="job-step-3">
        <h1 className="mb-10">
          Ladda upp ditt CV <span>(frivilligt)</span>
        </h1>
        <label
          className="w-full rounded-xl flex flex-col p-4 items-center justify-center cursor-pointer"
          style={{ border: "3px dashed #011E41", height: 216 }}
          htmlFor="cv"
          ref={drop}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.3337 5.33334H16.0003C14.5858 5.33334 13.2293 5.89525 12.2291 6.89544C11.2289 7.89563 10.667 9.25219 10.667 10.6667V53.3333C10.667 54.7478 11.2289 56.1044 12.2291 57.1046C13.2293 58.1048 14.5858 58.6667 16.0003 58.6667H48.0003C49.4148 58.6667 50.7714 58.1048 51.7716 57.1046C52.7718 56.1044 53.3337 54.7478 53.3337 53.3333V21.3333L37.3337 5.33334Z"
              stroke="#011E41"
              strokeOpacity="0.8"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.333 5.33334V21.3333H53.333"
              stroke="#011E41"
              strokeOpacity="0.8"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 48V32"
              stroke="#011E41"
              strokeOpacity="0.8"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24 40H40"
              stroke="#011E41"
              strokeOpacity="0.8"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {file ? (
            <>
              <div className="mt-7" style={{ color: "#616161" }}>
                {onUpload[0]?.name}
              </div>
              <div className="h-1 mt-3 w-36 bg-gray-200">
                <div
                  className={`h-full ease-in duration-200 w-${percentage} ${
                    percentage && "text-right bg-blue-900"
                  }`}
                ></div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-7" style={{ color: "#616161" }}>
                Dra & släpp fil hit eller{" "}
                <span className="underline" style={{ color: "#011E41" }}>
                  bläddra
                </span>
              </div>
            </>
          )}
        </label>
        <input
          type="file"
          // value={cv}
          onChange={handleFile}
          className="hidden"
          id="cv"
        />
      </div>
      <div className="job-step-3">
        <p className="description" style={{ margin: 0 }}>
          Genom att klicka på “Skicka ansökan” godkänner jag Putsos{" "}
          <a
            href={`${emailCitySufix}/integritets-och-cookiespolicy`}
            target="_blanko"
            rel="noopener noreferrer"
            className="underline"
            style={{ color: "#011E41" }}
          >
            integritetspolicy.
          </a>
        </p>
      </div>
      <div className="flex md:justify-between justify-end md:px-0 px-5 w-full mt-11">
        <BackButton text="Tillbaka" onClick={onBack} />
        <button
          type="submit"
          disabled={
            firstName === "" ||
            lastName === "" ||
            email === "" ||
            phone === "" ||
            personNumber === ""
          }
          style={{ width: 260 }}
        >
          Skicka ansökan
        </button>
      </div>
    </form>
  );
}
