import React, { useEffect, useState } from "react";
import "./JobApplication.scss";
import jobApp from "../../assets/images/job-app.png";
import LogoMobile from "../../assets/images/icons/logo-white.svg";
import { HiCheck } from "react-icons/hi";
import "../Booking/Steps/Steps.scss";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { sendJobMail } from "../../helpers/emails";

export default function JobApplication({
  emailCitySufix,
  hiddenNav,
  setHiddenNav,
}) {
  const [step, setStep] = useState(1);
  //eslint-disable-next-line
  const [step1Values, setStep1Values] = useState();
  //eslint-disable-next-line
  const [step2Values, setStep2Values] = useState();
  const [cv, setCv] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [personNumber, setPersonNumber] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (step > 1) {
      setHiddenNav(true);
    } else {
      setHiddenNav(false);
    }
    //eslint-disable-next-line
  }, [step]);

  const handleSubmit = (file) => {
    sendJobMail(
      step1Values,
      step2Values,
      firstName,
      lastName,
      email,
      phone,
      personNumber,
      notes,
      file,
      () => setStep(4)
    );
  };

  return (
    <>
      <div className={"job-top-header" + (hiddenNav ? " active" : "")}>
        <div aria-hidden onClick={() => setStep((p) => p - 1)}>
          <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_111_8)">
              <path
                d="M12.2 16.9L17.6 11.5L19 12.9L15 16.9L19 20.9L17.6 22.3L12.2 16.9Z"
                fill="#FAFAFA"
              />
            </g>
            <rect
              x="0.5"
              y="1"
              width="31"
              height="31"
              rx="3.5"
              stroke="#011E41"
            />
            <defs>
              <clipPath id="clip0_111_8">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(22 10.5) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <LogoMobile />
        <div className="w-8"></div>
      </div>
      <div className="job-application">
        <div className="max-w-7xl mx-auto md:px-6">
          {step === 1 && (
            <div className="md:flex items-center job-header">
              <div className="md:pl-14 md:pr-10 md:py-12 pl-5 pr-5 py-10 md:w-9/12">
                <h1>Bli en del av Putso</h1>
                <p>
                  Vår framgång sitter i våra medarbetare. Har du den talang och
                  framåtanda som krävs för att skapa kundnöjdhet utöver det
                  vanliga? Vill du växa med stort eget ansvar och gå till ett
                  roligt och omväxlande jobb? Då kan du vara vår nästa kollega!
                  Ansök nedan redan idag!
                </p>
              </div>
              <div className="md:w-3/12">
                <img src={jobApp} alt="Job app" className="md:block hidden" />
              </div>
            </div>
          )}

          <div className="Steps">
            <div className={"single-step" + (step >= 1 ? " active" : "")}>
              {step > 1 ? <HiCheck /> : 1}
            </div>
            <div className="line" />
            <div className={"single-step" + (step >= 2 ? " active" : "")}>
              {step > 2 ? <HiCheck /> : 2}
            </div>
            <div className="line" />
            <div className={"single-step" + (step >= 3 ? " active" : "")}>
              {step > 3 ? <HiCheck /> : 3}
            </div>
          </div>

          {/* {step === 1 && ( */}
          <Step1
            onSubmit={(val) => {
              setStep1Values(val);
              setStep(2);
              window.scrollTo(0, 0);
            }}
            active={step === 1}
          />
          {/* )} */}
          <Step2
            onBack={() => {
              setStep(1);
              window.scrollTo(0, 0);
            }}
            active={step === 2}
            onSubmit={(val) => {
              setStep2Values(val);
              setStep(3);
              window.scrollTo(0, 0);
            }}
          />

          {step === 3 && (
            <Step3
              onBack={() => {
                setStep(4);
                window.scrollTo(0, 0);
              }}
              emailCitySufix={emailCitySufix}
              cv={cv}
              setCv={setCv}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              personNumber={personNumber}
              setPersonNumber={setPersonNumber}
              notes={notes}
              setNotes={setNotes}
              handleSubmit={handleSubmit}
            />
          )}

          {step === 4 && (
            <div className="job-step-3">
              <div className="flex items-center">
                <div className="mr-4">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0C8.97231 0 0 8.97154 0 20C0 31.0285 8.97231 40 20 40C31.0277 40 40 31.0285 40 20C40 8.97154 31.0277 0 20 0ZM31.15 13.33L18.8423 27.1762C18.5392 27.5169 18.1169 27.6923 17.6915 27.6923C17.3538 27.6923 17.0146 27.5815 16.7308 27.3554L9.03846 21.2015C8.37538 20.6715 8.26769 19.7031 8.79846 19.0392C9.32846 18.3754 10.2977 18.2677 10.9608 18.7985L17.5123 24.0392L28.85 11.2846C29.4131 10.6492 30.3869 10.5923 31.0215 11.1569C31.6569 11.7223 31.7146 12.6946 31.15 13.33Z"
                      fill="#011E41"
                    />
                  </svg>
                </div>
                <div className="big-header">Tack för din ansökan!</div>
              </div>
              <p className="mt-6" style={{ fontSize: 16, lineHeight: "28px" }}>
                Vi ser alltid fram emot att få läsa igenom ansökningar från
                personer som vill jobba med oss på Putso. Vi vill tacka för
                att du har sökt jobb hos oss, och bekräftar att din ansökan har
                kommit fram. En av våra rekryterare kommer att kontakta dig inom
                kort och meddela dig om du blir vald till en ytterligare
                intervju eller inte.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
